$(document).ready(function() {
    $('.accordion').find('.accordion-headline').click(function(){

        //Expand or collapse this panel
        $(this).next().slideToggle('slow');

        //Hide the other panels
        $(".accordion-body").not($(this).next()).slideUp('slow');
    });
});

// main page smooth scroll
$(function() {
    $('a').click(function() {
        try {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                        $('.main').animate({
                            scrollTop: $('.main').scrollTop() + target.offset().top
                        }, 1000);
                    location.hash = "#" + this.hash.slice(1);
                    return false;
                }
            }
        } catch(err) {}
    });
});


// navigation and main page slide up
$(function() {
    $("#toggle").click(function() {
        $("#brand").toggleClass("active");
        $("#bg-jobs").toggleClass("active");
        $("#bg-jobs").toggleClass("mobile-d-none");
        $("#toggle").toggleClass("active");
        $("#instagram-icon").toggleClass("active");
        $("#navigation").toggleClass("nav-active");
        var main = $(".main");

        if ($( "#navigation" ).hasClass( "nav-active" )) {
            $( "#navigation" ).removeClass( "nav-close" ).addClass( "nav-open" );
        }
        else {
            $( "#navigation" ).removeClass( "nav-open" ).addClass( "nav-close" );
        }

        if (main.hasClass( "arrow" )) {
            main.removeClass( "arrow" ).addClass( "active" );
            main.removeClass( "close" ).addClass( "open" );
        }
        else {
            main.removeClass( "active" ).addClass( "arrow" );
            main.removeClass( "open" ).addClass( "close" );
        }
    });
});

$(function() {
    $(".imprint2").on('click', function() {
        $("#brand").addClass("active");
        $("#bg-jobs").addClass("active");
        $("#bg-jobs").addClass("mobile-d-none");
        $("#toggle").addClass("active");
        $("#instagram-icon").addClass("active");
        $("#navigation").addClass("nav-active");

        var main = $(".main");

        if ($( "#navigation" ).hasClass( "nav-active" )) {
            $( "#navigation" ).removeClass( "nav-close" ).addClass( "nav-open" );
        }
        else {
            $( "#navigation" ).removeClass( "nav-open" ).addClass( "nav-close" );
        }

        if (main.hasClass( "arrow" )) {
            main.removeClass( "arrow" ).addClass( "active" );
            main.removeClass( "close" ).addClass( "open" );
        }
        else {
        }

    });
});


// open the main page with the white button
$(function() {
    $(".main").click(function() {
        $("#brand").addClass("active");
        $("#bg-jobs").addClass("active");
        $("#bg-jobs").addClass("mobile-d-none");
        $("#toggle").addClass("active");
        $("#instagram-icon").addClass("active");
        $("#navigation").addClass("nav-active");
        var main = $(".main");

        if ($( "#navigation" ).hasClass( "nav-active" )) {
            $( "#navigation" ).removeClass( "nav-close" ).addClass( "nav-open" );
        }
        else {
            $( "#navigation" ).removeClass( "nav-open" ).addClass( "nav-close" );
        }

        if (main.hasClass( "arrow" )) {
            main.removeClass( "arrow" ).addClass( "active" );
            main.removeClass( "close" ).addClass( "open" );
        }
        else {
        }
    });
});


// navigation and main page slide up
$(function() {
    $("#brand").click(function() {
        $("#brand").toggleClass("active");
        $("#bg-jobs").toggleClass("active");
        $("#bg-jobs").toggleClass("mobile-d-none");
        $("#toggle").toggleClass("active");
        $("#instagram-icon").toggleClass("active");
        $("#navigation").toggleClass("nav-active");
        var main = $(".main");

        if ($( "#navigation" ).hasClass( "nav-active" )) {
            $( "#navigation" ).removeClass( "nav-close" ).addClass( "nav-open" );
        }
        else {
            $( "#navigation" ).removeClass( "nav-open" ).addClass( "nav-close" );
        }

        if (main.hasClass( "arrow" )) {
            main.removeClass( "arrow" ).addClass( "active" );
            main.removeClass( "close" ).addClass( "open" );
        }
        else {
            main.removeClass( "active" ).addClass( "arrow" );
            main.removeClass( "open" ).addClass( "close" );
        }
    });
});

$(function() {
    $("#bg-jobs").click(function() {
        $("#brand").toggleClass("active");
        $("#bg-jobs").toggleClass("active");
        $("#bg-jobs").toggleClass("mobile-d-none");
        $("#toggle").toggleClass("active");
        $("#instagram-icon").toggleClass("active");
        $("#navigation").toggleClass("nav-active");
        var main = $(".main");

        if ($( "#navigation" ).hasClass( "nav-active" )) {
            $( "#navigation" ).removeClass( "nav-close" ).addClass( "nav-open" );
        }
        else {
            $( "#navigation" ).removeClass( "nav-open" ).addClass( "nav-close" );
        }

        if (main.hasClass( "arrow" )) {
            main.removeClass( "arrow" ).addClass( "active" );
            main.removeClass( "close" ).addClass( "open" );
        }
        else {
            main.removeClass( "active" ).addClass( "arrow" );
            main.removeClass( "open" ).addClass( "close" );
        }
        setTimeout(function() {
                $('.main').animate({
                    scrollTop: $('.main').scrollTop() +$('#jobs').offset().top,
                }, 800);
        }, 1000);
    });
});

/**
 * jump to module by hashtag anchor
 */
$(document).ready(function() {
    var hash = window.location.hash.substr(1);
    if (hash.length) {
        $(".main").trigger("click");
        setTimeout(function() {
            var target = $('#' + hash);
            if (target.length) {
                $('.main').animate({
                    scrollTop: $('.main').scrollTop() + target.offset().top,
                }, 800);
            }
        }, 1000);
    }
});
